import React from 'react';

import { Row, Col, Card } from 'react-bootstrap';

import TripInfo from './TripInfo';

import Style from './Style'
import { extractStyle } from './Style';

import ListItem from './ListItem';
import { ListItemProps } from './ListItem';

import Fraction from './Fraction';

type FundedProps = {
	value: Fraction
}

class Funded extends React.Component<FundedProps, {}> {
	render() {
		const { value } = this.props;
		const confirmed = value.value() >= 1;
		if (confirmed) {
			return (
				<strong>
					Confirmed!
				</strong>
			);
		} else {
			return (
				<span>
					{value.toPercent()} funded
				</span>
			);
		}
	}
}

type BookedProps = {
	value: Fraction
}

class Booked extends React.Component<BookedProps, {}> {
	render() {
		const { value } = this.props;
		const remain = value.total - value.amount;
		const scarce = remain < 20;
		if (scarce) {
			return (
				<strong className="strong text-danger">
					{remain} <small>tickets remaining</small>
				</strong>
			);
		} else {
			return (
				<span>
					{value.toPercent()} booked
				</span>
			);
		}
	}
}

export interface TripProps extends ListItemProps<TripInfo>, Style {
};

export default class Trip extends ListItem<TripInfo, TripProps> {
	renderItem = () => {
		const { item } = this.props;
		const style = extractStyle(this.props);
		return (
			<Card {...style}>
				<Card.Header>
					<small>{item.from.format("D MMM")+' — '+item.to.format("D MMM")}</small>
				</Card.Header>
				<Card.Img variant="top" src={item.images.thumbnail} />
				<Card.Body>
					<Card.Title>
						{item.name}
					</Card.Title>
					<Card.Text>{item.description}</Card.Text>
				</Card.Body>
				<Card.Footer>
					<Row>
						<Col xs={12}><Funded value={item.funded} /></Col>
						<Col xs={12}><Booked value={item.booked} /></Col>
						<Col xs={12}>{item.cost.toString(true)}</Col>
					</Row>
				</Card.Footer>
			</Card>
		);
	}
}
