import React from 'react';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

import classnames from 'classnames';

import Menu from './Menu';
import ListView from './ListView';

import { title, menu, trips, tiers, tier_footnotes } from './Data';

import TripInfo from './TripInfo';
import TierInfo from './TierInfo';

import Trip from './Trip';
import { TripProps } from './Trip';
import Tier from './Tier';
import { TierProps } from './Tier';

import './Booking.css';

interface ListViewProps<T> {
	items: T[]
	preview?: T
	active?: T
	onSet: (value: T) => void
	onSelect: (value: T) => void
}

type TripFeatureImageProps = {
	trip: TripInfo,
	active: boolean,
	focus?: number,
};

const TripFeatureImage = ({ trip, active, focus }: TripFeatureImageProps) => { return (
		<div style={{ backgroundImage: `url(${trip.images.feature[0]})` }} className={classnames('trip-feature-image', { active: active, inactive: !active }, focus && `focus-${focus}`)} />
); };

type DepositFormSummaryLineProps = {
	title: string
	value: string
}

class DepositFormSummaryLine extends React.Component<DepositFormSummaryLineProps, {}> {
	render() {
		const { title, value } = this.props;
		return (
			<div className="p-2">
				<h6>{title}</h6>
				<strong className="pl-2">{value}</strong>
			</div>
		);
	}
}

type DepositFormProps = {
	trip?: TripInfo
	tier?: TierInfo
}

class DepositForm extends React.Component<DepositFormProps, {}> {
	render() {
		const { trip, tier } = this.props;
		if (trip && tier) {
			const deposit = trip.cost.scale(tier.deposit.value());
			const later = trip.cost.subtract(deposit);
			const trip_dates = `${trip.from.format('D MMM YYYY')} — ${trip.to.format('D MMM YYYY')}`;
			const outbound_dates = trip.outbound ? `${trip.outbound.depart.format('D MMM YYYY')} — ${trip.outbound.land.format('D MMM YYYY')}` : '(TBD)';
			const inbound_dates = trip.inbound ? `${trip.inbound.depart.format('D MMM YYYY')} — ${trip.inbound.land.format('D MMM YYYY')}` : '(TBD)';
			return (
				<Row className="small">
					<Col xs={12} md={12} lg={4}>
						<DepositFormSummaryLine title='Destination' value={trip.name} />
						<DepositFormSummaryLine title='Trip dates' value={trip_dates} />
						<DepositFormSummaryLine title='Tier' value={tier.name} />
						<DepositFormSummaryLine title='Outbound flight' value={outbound_dates} />
						<DepositFormSummaryLine title='Inbound flight' value={inbound_dates} />
						<DepositFormSummaryLine title='Deposit to pay now' value={deposit.toString()} />
						<DepositFormSummaryLine title='Amount to pay later' value={later.toString()} />
					</Col>
					<Col lg={4} className='d-none d-lg-block'>
						<Trip item={trip} border='info' />
					</Col>
					<Col lg={4} className='d-none d-lg-block'>
						<Tier item={tier} border='info' />
					</Col>
				</Row>
			);
		} else {
			return (
				<div />
			);
		}
	}
}

interface BookingState {
	stage: string;
	trip?: TripInfo;
	tier?: TierInfo;
	previewTrip?: TripInfo;
	previewTier?: TierInfo;
}

type BookingProps = {
};

class Booking extends React.Component<BookingProps, BookingState> {
	constructor(props: BookingProps) {
		super(props);
		this.state = { stage: 'trip', trip: undefined, tier: undefined };
	}
	getNextStage = (stage: string) => {
		const map: { [key: string]: string } = {
			'trip': 'tier',
			'tier': 'deposit',
			'deposit': 'deposit',
		};
		return map[stage];
	}
	goNextStage = () => {
		this.setState(prev => ({ ...prev, stage: this.getNextStage(prev.stage), previewTrip: undefined, previewTier: undefined }));
	}
	delayGoNextStage = () => {
		setTimeout(() => this.goNextStage(), 200);
	}
	onPreviewTrip = (previewTrip?: TripInfo) => {
		this.setState(prev => ({ ...prev, previewTrip }));
	}
	onPreviewTier = (previewTier?: TierInfo) => {
		this.setState(prev => ({ ...prev, previewTier }));
	}
	onSetTrip = (trip?: TripInfo) => {
		this.setState(prev => ({ ...prev, trip, previewTrip: undefined, previewTier: undefined }));
	}
	onSetTier = (tier?: TierInfo) => {
		this.setState(prev => ({ ...prev, tier, previewTrip: undefined, previewTier: undefined }));
	}
	onSelectTrip = (trip?: TripInfo) => {
		this.onSetTrip(trip);
		this.delayGoNextStage();
	}
	onSelectTier = (tier?: TierInfo) => {
		this.onSetTier(tier);
		this.delayGoNextStage();
	}
	onChangeTab = (eventKey: any, event?: any) => {
		this.setStage(eventKey as string);
	}
	setStage(stage: string) {
		if (this.isStageValid(stage)) {
			this.setState(prev => ({ ...prev, stage }));
		}
	}
	isStageValid = (stage: string): boolean => {
		return (stage === 'trip' ||
			(stage === 'tier' && this.state.trip) ||
			(stage === 'deposit' && this.state.trip && this.state.tier)) ? true : false;
	}
	isNextStageValid = (): boolean => {
		return this.isStageValid(this.getNextStage(this.state.stage));
	}
	isPreviewTripActive = (test: TripInfo) => {
		const { previewTrip, trip } = this.state;
		return previewTrip ? previewTrip === test : trip === test;
	}
	getPreviewTripFocus = (test: TripInfo) => {
		const { stage } = this.state;
		const { trip } = this.state;
		const { previewTier, tier } = this.state;
		const focusTier = previewTier || tier;
		return stage === 'trip' ? 2 : (focusTier && test === trip ? focusTier.level : undefined);
	}
	getTripKey(trip: TripInfo) {
		return trip.id;
	}
	getTierKey(tier: TierInfo) {
		return tier.id;
	}
	tripFactory = (props: TripProps) => {
		const { selected, preview} = props;
		return <Trip {...props} className={classnames('h-100', { selected, preview })} />
	}
	tierFactory = (props: TierProps) => {
		const { selected, preview} = props;
		const { trip } = this.state;
		return <Tier {...props} price={trip && trip.cost} className={classnames('h-100', { selected, preview })} />
	}

	render() {
		return (
			<div className='booking-form'>
				<Menu title={title} items={menu} />
				{trips.map(trip => <TripFeatureImage key={trip.name} trip={trip} active={this.isPreviewTripActive(trip)} focus={this.getPreviewTripFocus(trip)} />)}
				<Container className="mt-4 p-4">
					<Row>
						<Col>
							<Tabs defaultActiveKey='trip' activeKey={this.state.stage} id="booking-process-tabs" className="rounded-top border-bottom-0" onSelect={this.onChangeTab}>
								<Tab eventKey='trip' title='Choose your adventure' className="bg-light p-2 rounded-bottom rounded-right" disabled={!this.isStageValid('trip')}>
									<ListView items={trips} keyFunc={this.getTripKey} factory={this.tripFactory} onSelect={this.onSelectTrip} onPreview={this.onPreviewTrip} itemContainerClass='trip-item col col-xs-12 col-md-6 col-lg-4 col-xl-3 p-2' />
								</Tab>
								<Tab eventKey='tier' title='Choose your tier' className="bg-light p-2 rounded-bottom rounded-right" disabled={!this.isStageValid('tier')}>
									<ListView items={tiers} keyFunc={this.getTierKey} factory={this.tierFactory} onSelect={this.onSelectTier} onPreview={this.onPreviewTier} itemContainerClass='tier-item col col-xs-12 col-md-6 col-lg-3 p-2' />
									<Row className="mt-4">
										<Col>
											{tier_footnotes.map(footnote => <small key={footnote.key} className="d-block mt-1">{footnote.key} {footnote.text}<br/></small> )}
										</Col>
									</Row>
								</Tab>
								<Tab eventKey='deposit' title='Pay reservation' className="bg-light p-2 rounded-bottom rounded-right" disabled={!this.isStageValid('deposit')}>
									<DepositForm trip={this.state.trip} tier={this.state.tier} />
								</Tab>
							</Tabs>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

export default Booking;
