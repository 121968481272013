import moment from 'moment';

import Fraction from './Fraction';
import Price from './Price';
import TripInfo from './TripInfo';
import TierInfo from './TierInfo';
import TierFootnote from './TierFootnote';
import MenuItem from './MenuItem';

export const title: string = 'Piim Travel';

export const menu: MenuItem[] = [
	{ name: 'Search & book', target: '/' },
	{ name: 'How does Piim work?', target: '/about' },
	{ name: 'Careers at Piim', target: '/careers' },
];

export const trips: TripInfo[] = [
	{
		id: '001',
		name: 'Preston',
		description: 'Beautiful post-apocalyptic city, surrounded by scenic hills and roadkill.',
		from: moment('2019-08-25T11:25:00Z'),
		to: moment('2019-08-29T13:55:00Z'),
		cost: Price.euros(95),
		funded: new Fraction(4000, 12000),
		booked: new Fraction(42, 150),
		images: {
			thumbnail: 'https://hackology.co.uk/photos/213-_DSC0140.jpg',
			feature: [
				'https://hackology.co.uk/photos/213-_DSC0112.jpg',
			],
		},
	},
	{
		id: '002',
		name: 'Blackburn',
		description: 'Exciting northern town, with rich subculture - the performance car scene and heavy-metal pubs are a must-visit!',
		from: moment('2019-08-25T11:25:00Z'),
		to: moment('2019-08-29T13:55:00Z'),
		cost: Price.euros(118),
		funded: new Fraction(2600, 14000),
		booked: new Fraction(23, 150),
		images: {
			thumbnail: 'https://hackology.co.uk/wp-content/uploads/2013/06/DSC_0034.jpg',
			feature: [
				'https://hackology.co.uk/wp-content/uploads/2013/05/DSC_0015.jpg',
				'https://hackology.co.uk/wp-content/uploads/2013/07/DSC0087.jpg',
			],
		},
	},
	{
		id: '003',
		name: 'Narvik',
		description: 'Postcard-worthy fishing town, surrounded by astonishing scenery and a good craft beer community',
		from: moment('2019-08-25T11:25:00Z'),
		to: moment('2019-08-29T13:55:00Z'),
		cost: Price.euros(95),
		funded: new Fraction(9860, 8000),
		booked: new Fraction(108, 120),
		images: {
			thumbnail: 'https://hackology.co.uk/photos/201-_DSC0069.jpg',
			feature: [
				'https://hackology.co.uk/photos/201-_DSC0049.jpg',
			],
		},
	},
	{
		id: '004',
		name: 'Rockall',
		description: 'Worthless uninhabited rock poking out of the ocean, where the next world war will probably start',
		from: moment('2019-08-25T11:25:00Z'),
		to: moment('2019-08-29T13:55:00Z'),
		cost: Price.euros(225),
		funded: new Fraction(900, 32000),
		booked: new Fraction(4, 300),
		images: {
			thumbnail: 'https://hackology.co.uk/photos/173-_DSC0003.jpg',
			feature: [
				'https://hackology.co.uk/photos/173-_DSC0046.jpg',
			],
		},
	},
];

export const tiers: TierInfo[] = [
	{
		id: '1st',
		level: 1,
		name: '1st class',
		description: 'All perks at no extra cost',
		deposit: new Fraction(100, 100),
		refund: new Fraction(100, 100),
		terms: [
			'Carry-on luggage (big+small)',
			'20kg luggage included',
			'23kg luggage upgrade for €5',
			'Priority boarding',
			'Voucher for Tallinn Airport shops',
			'Free priority seat selection',
			'Free name change **',
			'Extra offers at destination ***',
		],
	},
	{
		id: '2nd',
		level: 2,
		name: '2nd class',
		description: 'Flexible freedom',
		deposit: new Fraction(50, 100),
		refund: new Fraction(75, 100),
		terms: [
			'Carry-on luggage (big+small)',
			'15kg luggage included',
			'20kg luggage upgrade for €10',
			'Voucher for Tallinn Airport shops',
			'Free seat selection after 1st class',
		],
	},
	{
		id: '3rd',
		level: 3,
		name: '3rd class',
		description: 'For the indecisive',
		deposit: new Fraction(30, 100),
		refund: new Fraction(50, 100),
		terms: [
			'Carry-on luggage (1 bag)',
			'15/20/23kg luggage for €1/kg',
			'Voucher for Tallinn Airport shops',
			'Free seat selection after 2nd class',
		],
	},
	{
		id: '4th',
		level: 4,
		name: '4th class',
		description: 'For the very indecisive',
		deposit: new Fraction(0, 100),
		refund: new Fraction(25, 100),
		terms: [
			'Carry-on luggage (1 bag)',
			'15/20/23kg luggage for €1/kg',
			'Voucher for Tallinn Airport shops',
			'Free seat selection after 3rd class',
		],
	},
];

export const tier_footnotes: TierFootnote[] = [
	{
		key: '*',
		text: 'Maximum fraction of total ticket price which can be refunded, where refunds are issued after (1) the customer has bought their ticket then (2) the customer has cancelled their trip then (3) the space has been sold to another traveller.',
	},
	{
		key: '**',
		text: 'Until 72 hours before flight is scheduled to depart',
	},
	{
		key: '***',
		text: 'from participating partners, subject to availability',
	},
];
