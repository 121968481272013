import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import 'bootstrap/scss/bootstrap.scss';
import './index.css';

import { title } from './Data';

import Booking from './Booking';
import About from './About';
import Careers from './Careers';

import * as serviceWorker from './serviceWorker';

/* Set page title */
const titleNode = document.getElementById('title-node');
if (titleNode !== null) {
	titleNode.innerHTML = title;
}

const routing = (
	<Router>
		<div>
			<Route exact={true} path="/" component={Booking} />
			<Route exact={true} path="/about" component={About} />
			<Route exact={true} path="/careers" component={Careers} />
		</div>
	</Router>
);

/* Bind app */
ReactDOM.render(routing, document.getElementById('content-node'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
