import React from 'react';

import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import MenuItem from './MenuItem';

import logo from './piim-logo.svg';

type MenuParams = {
	title: string;
	items: MenuItem[];
};

const Menu = ({ title, items }: MenuParams) => {
	return (
		<Navbar bg="light" variant="light" sticky="top" expand="md">
			<Navbar.Brand>
				<img src={logo} alt="Piim" style={{ height: '40px' }} />
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="ml-auto justify-content-end">
					{items.map(item => <LinkContainer key={item.target} to={item.target} exact={true}><Nav.Link href={item.target} active={window.location.pathname === item.target} className="mx-4">{item.name}</Nav.Link></LinkContainer>)}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default Menu;
