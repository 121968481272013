import DisplayStyle from './DisplayStyle'

export default interface Style {
	border?: DisplayStyle
	text?: DisplayStyle
	bg?: DisplayStyle
	className?: string
}

export const extractStyle = (style: Style): Style => {
	const { border, text, bg, className } = style
	return { border, text, bg, className }
}
