import React from 'react'

import classnames from 'classnames';

import ActionItem from './ActionItem'
import { ActionItemProps } from './ActionItem'

export interface ListItemProps<T> extends ActionItemProps<T> {
	selected?: boolean
	preview?: boolean
	itemContainerClass?: string
}

export default class ListItem<T, U extends ListItemProps<T>> extends ActionItem<T, U> {
	renderItem = (): any => {
		return []
	}
	render() {
		const { itemContainerClass } = this.props
		return (
			<div
				onMouseEnter={this.onMouseEnter}
				onMouseLeave={this.onMouseLeave}
				onMouseMove={this.onMouseMove}
				onClick={this.onClick}
				style={{ cursor: 'default' }}
				className={classnames('list-item', itemContainerClass)}
			>
				{this.renderItem()}
			</div>
		)
	}
}
