import React from 'react';

import { Card, ListGroup } from 'react-bootstrap';

import TierInfo from './TierInfo';

import Price from './Price'

import Style from './Style'
import { extractStyle } from './Style';

import ListItem from './ListItem';
import { ListItemProps } from './ListItem';

type TierPriceProps = {
	value?: Price
};

class TierPrice extends React.Component<TierPriceProps, {}> {
	render() {
		const { value } = this.props;
		if (value) {
			return (
				<span className="float-right small">
					{value.toString(true)}
				</span>
			);
		} else {
			return ( <span /> );
		}
	}
}

export interface TierProps extends ListItemProps<TierInfo>, Style {
	price?: Price
};

export default class Tier extends ListItem<TierInfo, TierProps> {
	renderItem = () => {
		const { item, price } = this.props;
		const terms: string[] = [
			`${item.deposit.toPercent()} deposit`,
			`${item.refund.toPercent()} refundable *`,
			...item.terms
		];
		const style = extractStyle(this.props);
		return (
			<Card {...style}>
				<Card.Header>
					<Card.Title>
						{item.name}
						<TierPrice value={price} />
					</Card.Title>
				</Card.Header>
				<Card.Body className="flex-grow-0">
					<Card.Text>{item.description}</Card.Text>
				</Card.Body>
				<ListGroup variant="flush" className="tier-terms mb-auto">
					{terms.map(term => <ListGroup.Item key={term} className="p-2"><small>{term}</small></ListGroup.Item> )}
				</ListGroup>
			</Card>
		);
	}
};
