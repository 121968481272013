import { Money, Currencies, Currency } from 'ts-money';

const C: { [key: string]: Currency } = Currencies;

export default class Price {
	money: Money
	constructor(amount: number, currency: Currency) {
		this.money = Money.fromDecimal(amount, currency);
	}
	toString(round: boolean = false): string {
		var m = this.money;
		var c = C[m.currency];
		if (round) {
			return `${c.symbol}${Math.ceil(m.amount / Math.pow(10, c.decimal_digits))}`;
		} else {
			return `${c.symbol}${m.toString()}`;
		}
	}
	static Currencies() {
		return Currencies;
	}
	static euros(amount: number) {
		return new Price(amount, Currencies.EUR);
	}
	static from(money: Money) {
		const m = new Price(0, C.EUR);
		m.money = money;
		return m;
	}
	add(amount: Price): Price {
		return Price.from(this.money.add(amount.money));
	}
	subtract(amount: Price): Price {
		return Price.from(this.money.subtract(amount.money));
	}
	scale(by: number): Price {
		return Price.from(this.money.multiply(by, Math.ceil));
	}
}
