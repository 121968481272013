import React from 'react'

import { Container, Row } from 'react-bootstrap'

import Style from './Style';
import { extractStyle } from './Style';

import { ListItemProps } from './ListItem';

export interface ListViewProps<T> extends Style {
	items: T[]
	keyFunc: (item: T) => string | number
	factory: React.FC<ListItemProps<T>>
	onSelect?: (item: T) => void
	onPreview?: (item?: T) => void
	itemContainerClass?: string
}

export interface ListViewState<T> {
	selected?: T
	preview?: T
}

export default class ListView<T> extends React.Component<ListViewProps<T>, ListViewState<T>> {
	constructor(props: ListViewProps<T>) {
		super(props)
		this.state = { }
	}
	onItemClick = (item: T) => {
		this.setState(prev => ({ ...prev, selected: item }));
		this.props.onSelect && this.props.onSelect(item);
	}
	onItemMouseEnter = (item: T) => {
		this.setState(prev => ({ ...prev, preview: item }));
		this.props.onPreview && this.props.onPreview(item);
	}
	onItemMouseLeave = (item: T) => {
		this.setState(prev => ({ ...prev, preview: undefined }));
		this.props.onPreview && this.props.onPreview(undefined);
	}
	render() {
		const { itemContainerClass, children, keyFunc, items } = this.props;
		const style = extractStyle(this.props)
		const { selected, preview } = this.state;
		return (
			<Container>
				<Row className="align-items-stretch">
					{items.map((item: T) => (<this.props.factory key={keyFunc(item)} {...style} item={item} selected={item === selected} preview={item === preview} onClick={this.onItemClick} onMouseEnter={this.onItemMouseEnter} onMouseLeave={this.onItemMouseLeave} itemContainerClass={itemContainerClass} />))}
				</Row>
				{children}
			</Container>
		);
	}
}
