import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Menu from './Menu';

import { title, menu } from './Data';

const Careers = () => { return (
	<div>
		<Menu title={title} items={menu} />
		<Container className="mt-4 p-4 rounded-lg bg-light border-secondary">
			<Row>
				<Col>
					<h1>Careers at Piim</h1>
					<p>
						Piim is possible thanks to innovative solutions, engineering and design.
					</p>
					<p>
						This includes:
					</p>
					<ul>
						<li>Web front-end, built on Bootstrap, React.js and TypeScript</li>
						<li>Web back-end, built on C# .NET MVC</li>
						<li>Infrastructure, microservices managed by Kubernetes</li>
						<li>Planning and optimisation algorithms</li>
					</ul>
					<p>
						If you enjoy solving problems, communicating knowledge, and can contribute in these areas, then solve one of our challenge projects to figure out how to apply:
					</p>
					// TODO - challenge projects
					<p>
						TODO
					</p>
				</Col>
			</Row>
		</Container>
	</div>
); }

export default Careers;
