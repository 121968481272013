import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Menu from './Menu';

import { title, menu, tiers, tier_footnotes } from './Data';

import Tier from './Tier';

const About = () => { return (
	<div>
		<Menu title={title} items={menu} />
		<Container className="mt-4 p-4 rounded-lg bg-light border-secondary">
			<Row>
				<Col>
					<p className="text-center h4 d-block my-4 font-italic">
						Booking flights with traditional airlines can be a painful process.
					</p>
					<p>
						With prices randomly skyrocketing, different hidden fees with each airline for luggage and seating, page after page of attempts to sell car rental / insurance / train or bus tickets..........
					</p>
					// TODO: Image showing pain of booking with RyanAir/airBaltic
					<p>
						I could go on:
					</p>
					<ul>
						<li>Chaotic (and sometimes insane) price changes</li>
						<li>Extra charges everywhere - checked luggage, carry-on luggage, seat selection, ...</li>
						<li>Overbooking — will your ticket actually get you onto the plane?  Or despite having paid for a already, will you end up having to fly a day late due to the plane running out of seats?</li>
						<li>Sometimes it's cheaper to buy luggage, seat, etc separately.  Other times, it's cheaper to buy the pricier ticket type which incldues all that already.  Booking takes time for the price-conscious.</li>
						<li>Spam for travel insurance, hotels, car rentals, ...</li>
						<li>No refunds if your plans change, or the ability to get a refund requires paying 3x more up-front for the ticket in the first place</li>
					</ul>
					<p>
						Overbooking and "no-refunds" are related.
						The airline assumes that a certain % of passengers won't turn up for various reasons, so it sells more tickets than there are seats on the plane (overbooking).
						Why refund people who's plans changed, when you can sell their seat twice and instead?
						The downside to this of course is passengers losing money when their plans change, and passengers losing time (and hair!) when they find the plane has run out of seats and they're bumped onto another flight hours or a day later due to overbooking.
					</p>
					<p>
						Then there is also inherent inefficiency due to nearly-empty planes where tickets didn't sell.
						This is bad for the environment, and probably isn't great for the airline's balance-sheet either.
						Sometimes it may be offset by having a completely full plane flying out before it then flies back empty, but other times, it's just a waste of resources.
						For airlines in small, low-populated regions (or countries), this can happen a lot - making it extremely difficult for those airlines to survive.
					</p>
					<p className="text-center h4 d-block my-4 font-italic">
						So why Piim?
					</p>
					// Diagram of Piim pipeline, crowdfund->confirm->pay->fly
					// Base ticket is same cost regardless of deposit paid
					// Paying larger deposit (or earlier) gets more perks
					<p>
						Piim crowdfunds flights in advance, charging partially-refundable deposits.
					</p>
					<p>
						Once enough people have paid deposits for a flight, the flight is confirmed and people are charged the rest of the ticket cost (minus the deposit already paid).
					</p>
					<p>
						Piim never overbooks flights, and will pay refunds when passengers want to cancel their flight, provided another person buys the space booked by the original passenger.
					</p>
					<p>
						Due to this, there is no need to overbook planes, and no risk of planes being catastrophically under-booked either.
						The only problem is what to do with the plane between the outbound- and the return-flight.
						Ideally, the plane would provide the reverse trip during this time, however this requires selling two sets of tickets for return-trips, with one set sold to a different country.
						However since the entire flight is crowdfunded, this (or the alternative, pay to park the plane for a few days) can be planned for before the flight is confirmed.
					</p>
					<p className="text-center h4 d-block my-4 font-italic">
						How do the tiers / perks / refunds work?
					</p>
					<p>
						During the crowdfund stage, people can choose how much of the ticket cost they want to commit as a deposit.
						For planning purposes, it would be ideal if people paid the full ticket cost at this stage however that is not convenient for most people, especially if the flight is not yet confirmed.
					</p>
					<p>
						So there are several different tiers instead, with different deposit sizes.
						The total ticket cost (including deposit) is the same regardless of which tier you buy, however there are extra perks (rewards) for paying more of the ticket cost up-front as deposit, rather than deferring until later.
					</p>
					<p>
						If the crowdfund-stage raises enough money to convince us that enough people want to travel, then the flight is confirmed, deposits are locked, and people are requested to pay the rest of their ticket price.
					</p>
					<p>
						If the crowdfund-stage is not successful, then deposits are refunded, or can be transferred to back other trips.
					</p>
					<p>
						If your plans change after the flight is confirmed, you can cancel your trip and request a refund.
						Once Piim has sold your space to another traveller, then you will receive a refund or a partial refund, depending on the tier you crowdfunded at.
					</p>
					<p>
						In order to encourage people to pre-order flights earlier rather than later, some extra perks might be offered for a limited time during the early stages.
						When this is the case, then the expiration date of those offers will be clearly visible so that there are no hidden surprises for people.
						The price however will <em>not</em> increase as time passes.
					</p>
					<p className="text-center h4 d-block my-4 font-italic">
						What if I'm interested in several trips?
					</p>
					<p>
						If you're interested in several trips but only have time to do one of them, then you can register interest in them all and pay one deposit, equal to the highest deposit of your desired trips.
						Once one or more of the trips are confirmed, you can choose which one you want to buy onto.
						Until one of the trips has been confirmed, you can cancel at any time and receive your full deposit back.
					</p>
					<p className="text-center h4 d-block my-4 font-italic">
						How to the different tiers compare?
					</p>
					<p className="text-center small d-block mt-2 mb-4">
						The full ticket-price is the same for all tiers, unless extras are purchased.
					</p>
					<Container>
						<Row className="mb-4">
							{tiers.map(tier => <Col key={tier.name} xs={12} sm={6} lg={3}><Tier item={tier} /></Col>)}
						</Row>
						{tier_footnotes.map(({ key, text }) => <Row key={key}><Col><small>{key} {text}</small></Col></Row>)}
					</Container>
				</Col>
			</Row>
		</Container>
	</div>
); }

export default About;
