import React from 'react'

import Action from './Action'

export interface ActionItemProps<T> extends Action<T> {
	item: T
}

export default abstract class ActionItem<T, U extends ActionItemProps<T>> extends React.Component<U, {}> {
	onMouseEnter = () => {
		const callback = this.props.onMouseEnter
		callback && callback(this.props.item)
	}
	onMouseLeave = () => {
		const callback = this.props.onMouseLeave
		callback && callback(this.props.item)
	}
	onMouseMove = () => {
		const callback = this.props.onMouseMove
		callback && callback(this.props.item)
	}
	onClick = () => {
		const callback = this.props.onClick
		callback && callback(this.props.item)
	}
}
