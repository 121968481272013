export default class Fraction {
	amount: number;
	total: number;
	constructor(amount: number, total: number) {
		this.amount = amount;
		this.total = total;
	}
	value() {
		return this.amount / this.total;
	}
	toPercent(precision: number = 0): string {
			return `${(this.amount * 100 / this.total).toFixed(precision)}%`;
	}
	toVulgar(): string {
			return `${this.amount}/${this.total}`;
	}
	toDecimal(precision: number = 2): string {
			return `${(this.amount / this.total).toFixed(precision)}`;
	}
	toString(): string {
		return this.toPercent();
	}
}
